const strings = {
    martingale_bot: "마틴게일봇",
    grid_v3_bot: "교차거래봇",
    annualized_return: "연간수익률",
    lasting: "운행시간",
    grid_transaction: "완료된 라운드 수",
    parameters: "주문 파라미터",
    fund_unit: "부수",
    grid_order_lasting_day: "일",
    grid_order_lasting_hour: "시간",
    grid_order_lasting_minute: "분",
    grid_order_lasting_second: "초",
    martingale_take_profit_ratio: "얼마 벌면 익절",
    martingale_price_range: "가격 범위",
    martingale_safety_orders: "최대 포지션 추가 횟수",
    martingale_price_scale: "얼마 떨어지면 추매",
    martingale_order_volume_scale: "추매 배",
    trade_trigger_price: "촉발가격",
    martingale_take_profit_percentage: "익절 비율",
    martingale_take_profit_trailing_percentage: "얼마 떨어지면 매도",
    martingale_price_scale_trailing_percentage: "반등시 매수",
    martingale_DIY_shares: "매수 부수",
    martingale_DIY_trailing: "얼마 반등하면 매수",
    share_introduce: "이것은 나의 $symbol$$strategy_bot$", // eg: 这是我的BTC/USDT马丁格尔机器人
    share_nickname: "나는 $nickname$", // eg:  我是Haze
    profit_rate: "수익률",
    snapshot_on: "$date$로 계산할 때의 수익 데이터",
    martingale_rounds: "라운드",
    copy_in_one_click: "주문 카피하기",
    share_order_closed: "주문이 종료되었습니다",
    martingale_stop_loss_percentage: "손절 비율(총 수익)",
    unrealized_stop_loss_percentage: "손절 비율(변동손익)",
    open_app: "파이넥스 앱 로딩중...",
    number_of_grids: "교차수",
    profit_rate_per_grid: "교차당 수익률",
    take_profit_price: "익절가",
    stop_loss_price: "손절가",
    price_range: "가격범위",
    transactions: "아비트리지 횟수",
    default_nickname: "파이넥스 유저",

    // 0216 new
    sr_rules_modify_by_time_interval_none: "리밸런스 중지",
    sr_rules_modify_title: "리밸런스 조건",
    share_sr_enter_alert_trigger_threshold_desc: "비율 변화 초과", // eg: 佔比變化超過 10%
    rebalanced_bot: "리밸런스봇",
    order_state_not_triggered: "트리거되지 않음",

    trade_total_profit_rate: "총 수익률",
    grid_spl_set_delay_exist: "(지연 $delay$초)",
    futures_coin_margin: "인버스",
};

export default strings;
