const strings = {
    confirm_copy_order_closed_title: "Бот закрыт.",
    confirm_copy_order_closed_continue: "Шарер закрыл бота. Вы уверены, что хотите продолжить копирование ордера?",
    button_cancel: "Отмена",
    subscription_success_confirm: "Скопируйте",
    futures_grid_v2_share_title: "Фьючерсной грид-бот $name$",
    trade_trigger_price: "Триггерная цена",
    future_init_price: "Платежная цена",
    order_state_not_triggered: "Не сработало",
    future_current_price: "Текущая цена",
    grid_position_share_ratio_percent: "Коэффициент распределения прибыли",
    futures_grid_v2_total_profit: "Текущая доходность",
    futures_grid_v2_grid_profit_apr: "Годовая доходность грид-арбитража",
    lasting: "Время работы",
    futures_grid_v2_order_create_time: "Время начала",
    futures_grid_v2_create_order: "Скопировать сейчас",
    grid_futures_invited_only_tip: "Этот ордер могут скопировать только пользователи, приглашенные $creator$.",
    futures_grid_v2_share_code: "Пригласительный код $name$",
    grid_futures_share_link_qr_code: "Отсканируйте код, чтобы немедленно скопировать ордер",
    grid_futures_order_closed: "Трейдер остановил бота.",
    futures_coin_margin: "Инверс",
};

export default strings;
